<template>
  <b-overlay :show="is_loading">
    <div v-if="reprise.id">
      <b-row>

        <b-col md="9">
          <b-card no-body>
            <b-card-header>
              <b-card-title>Information véhicule</b-card-title>

            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <vehicule-overview :vehicule="reprise.vehicule" />
                </b-col>
                <b-col md="auto">
                  <div>
                    <b-badge class="mb-1" variant="info"> {{reprise.statut_vente.label}}</b-badge><br/>
                    <b-badge class="mb-1" :variant="reprise.statut_cotation.variant"> {{reprise.statut_cotation.libelle}}</b-badge><br/>
                    <b-badge class="mb-1" :variant="reprise.statut_expertise_manuelle.variant"> {{reprise.statut_expertise_manuelle.libelle}}</b-badge><br/>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <vehicule-expertise @update="update_reprise" :statut-expertise-manuelle="reprise.statut_expertise_manuelle" :initial-reprise="reprise" />

        </b-col>
        <b-col md="3">
          <vehicule-la-cote :vehicule-id="$route.params.id"/>
          <client-card :editable="false" @client-selected="clientSelectedHandler" :initial-client="reprise.proprietaire"/>
          <b-card title="Prix">
            <ul class="list-unstyled">
              <li class="price-detail mb-1 d-flex justify-content-between">
                <div class="detail-title">
                  Offre en cours
                </div>
                <div class="detail-amt">
                  {{ reprise.offre_en_cours.prix }} Dhs
                </div>
              </li>

              <li class="price-detail mb-1 d-flex justify-content-between">
                <div class="detail-title">
                  Total frais
                </div>
                <div class="detail-amt">
                  {{ total_expertise_manuelle }} Dhs
                </div>
              </li>

            </ul>
            <hr>
            <ul class="list-unstyled">
              <li class="price-detail mb-1 d-flex justify-content-between">
                <div class="detail-title detail-total">
                  Total
                </div>
                <div class="detail-amt font-weight-bolder">
                  {{
                    (reprise.offre_en_cours.prix * 1) - (total_expertise_manuelle * 1)
                  }} Dhs
                </div>
              </li>
            </ul>
          </b-card>
          <b-card no-body >
            <b-card-header>
              <b-card-title>Offre <small class="text-muted" v-if="reprise.offre_en_cours.par">Par {{reprise.offre_en_cours.par}}</small></b-card-title>
              <b-card-title class="text-primary">
                {{reprise.offre_en_cours.prix}} Dhs
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <b-form-group label="Prix">
                <b-form-input :disabled="!$can('coter-vehicule', 'reprise')" v-model="offre_en_cours.prix" type="number" />
              </b-form-group>
              <div v-if="$can('coter-vehicule', 'reprise')">
                <b-button :disabled="reprise.offre_en_cours.prix == offre_en_cours.prix" size="sm" class="mr-1" @click="putOffreHandler" variant="primary" >Ajuster</b-button>
                <b-button :disabled="!reprise.offre_en_cours.prix || reprise.statut_cotation.id == 2"  @click="valideOffreHandler" size="sm" variant="primary" >Valider l'offre</b-button>
              </div>
            </b-card-body>

          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-card v-if="$can('creer-offre-reprise', 'list-bdc')">


            <b-button :disabled="reprise.statut_cotation.id != 2 || pending" class="ml-1" @click="creerOffreHandler" variant="primary">Créer offre</b-button>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>

</template>

<script>
import VehiculeLaCote from "@/components/Vehicule/VehiculeLaCote";
import ClientCard from "@/components/ClientCard";
import VehiculeExpertise from "@/components/VehiculeExpertise";
import axios from "@/libs/axios";
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview";

export default {
  name: "RepriseView",
  components: {VehiculeOverview, VehiculeExpertise, ClientCard, VehiculeLaCote},
  data() {
    return {
      reprise: {},
      offre_en_cours : 0,
      is_loading : true,
      validation_offre_en_cours : false,
      pending : false,
    }
  },
  computed : {
    total_expertise_manuelle(){
      let total = 0;
      // this.reprise.expertise_manuelle.forEach(function (item){
      //   if(item.montant){
      //     total+= item.montant * 1;
      //   }
      //
      //  
      // });
      return total;
    }
  },
  methods: {
    valideOffreHandler(){
      let _this = this;
      
 

      this.$bvModal
          .msgBoxConfirm(`Voulez vous vraiment valider l\'offre ?
           un SMS sera envoyé au vendeur et client`, {
            title: 'Validation offre',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              _this.validation_offre_en_cours = true;
              axios.post('/api/vehicules/reprise/' + this.reprise.id + '/valider-offre').then(function (response){

                _this.reprise = response.data.reprise;
                _this.offre_en_cours = {..._this.reprise.offre_en_cours}
                _this.validation_offre_en_cours = false;
              }) .catch(function (error) {
                _this.validation_offre_en_cours = false;
              });
            }
          });
      
      
 
    },
    creerOffreHandler(){
      let _this = this;
      _this.pending = true;
      this.$store.dispatch('offreModule/store', {
        type : "0",
        client : _this.reprise.proprietaire,
        accessoires : [],
        frais : [],
        accompte : 0,
        accompte_type : '',
        ventes : [],
        reprises : [{
          vehicule : {
            id : _this.reprise.id
          }
        }],
      }).then(function (response) {
        _this.reprise = response.data.data;
        _this.offre_en_cours = {...response.data.data.offre_en_cours};
        _this.pending = false;
      }).catch(function(error){
        if (error.response) {
          _this.form_errors = error.response.data.errors;
        }
      })
    },
    putOffreHandler(){
      let _this = this;
      _this.is_loading = true;
      axios.post('/api/vehicules/reprise/'  + this.reprise.id + '/set-offre', {
        prix : _this.offre_en_cours.prix
      }).then(function (response){
        _this.is_loading = false;
        console.log(response.data.reprise, 'putOffre');
        _this.reprise = response.data.reprise;
        _this.offre_en_cours = {...response.data.reprise.offre_en_cours}
   
      });
    },
    fetch() {
      let _this = this;
      _this.is_loading = true;
      this.$store.dispatch('repriseModule/fetch', {
        reprise_id: this.$route.params.id
      }).then(function (response) {
        _this.is_loading = false;
        _this.reprise = response.data;
        _this.offre_en_cours = {...response.data.offre_en_cours}
        console.log(response);
      })
    },
    clientSelectedHandler(client){
      this.reprise.proprietaire = {...client};
      let data = {
        client_id : client.id
      };
      axios
          .post('/api/vehicules/reprise/' + this.reprise.id + '/update-client', data)
          .then(function () {

          });
      console.log('enregistrerAccessoire');
    },
    update_reprise(reprise){
      console.log(reprise);
       this.reprise = reprise;
    }
  },
  created() {
    this.fetch();
  }
}
</script>

<style scoped>

</style>