<template>
  <b-tabs pills v-if="$can('gestion-expertise-manuelle', 'reprise') || $can('gestion-expertise-dekra', 'reprise')">
    <b-tab v-if="$can('gestion-expertise-dekra', 'reprise')" active title="Expertise dekra">
      <b-card>
        <div class="text-center text-danger" >
          <b-button :disabled="envoi_dekra_en_cours" v-if="initialReprise.statut_expertise_manuelle.id==0" @click="demanderExpertiseHandler" variant="primary">
            <b-spinner v-if="envoi_dekra_en_cours" small></b-spinner>
            Demande Expertise Dekra
          </b-button>
          <b-button v-if="initialReprise.statut_expertise_manuelle.id==3"  disabled  variant="primary">Demande Dekra en cours  Ref : ( {{initialReprise.demande_dekra_id}} )</b-button>
          <b-button v-if="initialReprise.statut_expertise_manuelle.id==3" class="ml-2" :disabled="envoi_dekra_en_cours" @click="check_dekra"  variant="primary">Check dekra</b-button>
        </div>
        
        <div >
          <table class="table table-sm" v-if="initialReprise.expertise_dekra_items && initialReprise.expertise_dekra_items.items">
            <thead>
            <tr>
              <th>Famille</th>
              <th>Libellé</th>
              <th>Opération</th>
              <th>Montant</th>
            </tr>
            </thead>
            <tbody>

            <tr :key="item.id" v-for="item in  initialReprise.expertise_dekra_items.items">
              <td>{{ item.groupe }}</td>
              <td>{{ item.libelle }}</td>
              <td>{{ item.operation }}</td>
              <td>{{ item.montant }}</td>
            </tr>
            <tr class="bg-dark p-2 font-weight-bolder text-white">
              <td></td>
              <td></td>
              <td>Total (HT) : </td>
              <td>{{initialReprise.expertise_dekra_items.total.toLocaleString('en')}} Dhs</td>
            </tr>
            <tr class="bg-dark p-2 font-weight-bolder text-white">
              <td></td>
              <td></td>
              <td>Total (TTC) : </td>
              <td>{{(initialReprise.expertise_dekra_items.total * 1.2).toLocaleString('en')}} Dhs</td>
            </tr>
            </tbody>
          </table>
          <b-button v-if="initialReprise.demande_expertise.statut == 0"  size="sm" class="mx-auto" variant="primary">Demande d'expertise</b-button>
        </div>
      </b-card>

    </b-tab>
    <b-tab v-if="$can('gestion-expertise-manuelle', 'reprise')" title="Expertise manuelle">
      <b-card>
        <b-row class="mb-1 align-items-center">
          <b-col md="4" class="text-right">
           <strong> TOTAL CARROSSERIE :</strong>
          </b-col>
          <b-col md="8">
            <b-form-input :disabled="statutExpertiseManuelle.id == 2" v-model="reprise.expertise_manuelle.total_carrosserie" type="number"/>
          </b-col>
        </b-row>
        <b-row class="mb-1 align-items-center">
          <b-col md="4" class="text-right">
            <strong class="text-uppercase"> TOTAL mécanique :</strong>
          </b-col>
          <b-col md="8">
            <b-form-input :disabled="statutExpertiseManuelle.id == 2" v-model="reprise.expertise_manuelle.total_mecanique"   type="number"/>
          </b-col>
        </b-row>
        <b-row class="mb-1 align-items-center">
          <b-col md="4" class="text-right">
            <strong class="text-uppercase"> Total Accessoires :</strong>
          </b-col>
          <b-col md="8">
            <b-form-input :disabled="statutExpertiseManuelle.id == 2" v-model="reprise.expertise_manuelle.total_accessoires"   type="number"/>
          </b-col>
        </b-row>
        <b-row class="mb-1 align-items-center">
          <b-col md="4" class="text-right">
            <strong> AUTRES :</strong>
          </b-col>
          <b-col md="8">
            <b-form-input :disabled="statutExpertiseManuelle.id == 2" v-model="reprise.expertise_manuelle.total_autres"   type="number"/>
          </b-col>
        </b-row>
        <b-row class="" v-if="false">

          <b-col md="2">Famille</b-col>
          <b-col md="4">Libellé</b-col>
          <b-col md="3">Opération</b-col>
          <b-col md="2">Montant</b-col>
          <b-col md="1"></b-col>

        </b-row>
        

 
        <hr/>
        <div class="d-flex justify-content-between">
          <div>
            <b-button @click="terminerHandler" variant="primary" size="sm">Terminer l'expertise</b-button>
          </div>
          <div>
            <b-button @click="saveHandler" class="" variant="primary" size="sm">Enregistrer</b-button>
          </div>
        </div>
        <div class="text-right">


        </div>
      </b-card>

    </b-tab>
  </b-tabs>
</template>

<script>
import axios from "@/libs/axios";

export default {
  name: "VehiculeExpertise",
  props: {
    initialReprise: {
      required: true
    },
    statutExpertiseManuelle : {
      required : true
    }
  },
  methods: {
    ajouterHandler() {
 
    },
    saveHandler() {
      let _this = this;
      axios.post('/api/vehicules/reprise/' + this.reprise.id + '/store-expertise-manuelle', {
        expertise: _this.reprise.expertise_manuelle
      }).then(function (response) {
        _this.$emit('update', response.data.reprise)
      });
    },
    supprimerHandler(index) {
      this.reprise.expertise_manuelle.splice(index, 1)
      console.log(index);
    },
    terminerHandler() {
      let _this = this;
      axios.post('/api/vehicules/reprise/' + this.reprise.id + '/terminer-expertise-manulle', {}).then(function (response) {
        console.log(response.data.reprise, 'response.data.data');
        _this.$emit('update', response.data.reprise)

      });
    },
    onSearch(search, loading) {
      if (!search) {
        return false;
      }
      let _this = this;
      axios.get('/api/expertise/get-labels?q=' + search).then(function (response) {
        _this.libelle_options = response.data
      })
    },
    demanderExpertiseHandler(){
      let _this = this;
      _this.envoi_dekra_en_cours = true;
      axios.post('/api/dekra/demander-expertise', {
        vehicule_id : this.reprise.vehicule.id
      }).then(function (response){
        console.log(response.data.data);
        _this.$emit('update', response.data.data);
        _this.envoi_dekra_en_cours = false;
      })
 
    },
    check_dekra(){
      let _this = this;
      _this.envoi_dekra_en_cours = true;
      axios.post('/api/dekra/check-expertise', {
        vehicule_id : this.reprise.vehicule.id
      }).then(function (response){
 
        _this.$emit('update', response.data.data);
        _this.envoi_dekra_en_cours = false;
      });
      
      console.log('---')
    }
    
    
  },
  data() {
    return {
      reprise: {},
      expertise: [],
      libelle_options: [],
      envoi_dekra_en_cours : false,
    }
  },
  created() {
    this.reprise = this.initialReprise
  }
}
</script>

<style scoped>

</style>